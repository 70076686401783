import { Button, Col, Row } from 'antd'
import { styled } from 'goober'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { AppModal } from 'components/AppModal'
import { Txt } from 'components/Txt'
import { DashIfNull } from 'helpers/string-helper'
import { backendPaths } from 'paths/backend-path-config'
import { IGetUserResponse } from 'services/user/user-response'

type UserDetailModalType = {
  user: IGetUserResponse
  visible: boolean
  onClickEdit: () => void
  onCloseModal: () => void
}

export const UserDetailModal = (props: UserDetailModalType) => {
  const { visible, onCloseModal, onClickEdit, user } = props

  const displayUserName = useMemo(() => {
    const { prefix, firstname, lastname } = user
    return `${prefix}${firstname} ${lastname}`
  }, [user])

  const userEditPath = useMemo(() => backendPaths.userEdit({ queryParam: { userId: user.userid } }), [user.userid])

  return (
    <>
      <AppModal visible={visible} width={800}>
        <AppModal.Header onCloseClick={onCloseModal}>ข้อมูลผู้ใช้งาน</AppModal.Header>
        <AppModal.Body>
          <Row>
            <RowItem label="ชื่อ - นามสกุล" value={displayUserName}></RowItem>
          </Row>
          <Row>
            <RowItem label="อีเมล" value={DashIfNull(user.email)}></RowItem>
            <RowItem label="เบอร์โทรศัพท์" value={user.tel.toString()}></RowItem>
          </Row>
          <Row>
            <RowItem label="กลุ่มผู้ใช้งาน" value={DashIfNull(user.userGrp)}></RowItem>
            <RowItem label="ชื่อโปรไฟล์" value={DashIfNull(user.profile)}></RowItem>
          </Row>
          <Row>
            <RowItem label="Account Type" value={DashIfNull(user.accountType)}></RowItem>
            <RowItem label="Logon" value={DashIfNull(user.logon)}></RowItem>
          </Row>
          <Row>
            <RowItem label="สถานะผู้ใช้งาน (Status)" value={user.active ? 'Active' : 'Inactive'}></RowItem>
          </Row>
        </AppModal.Body>
        <AppModal.Footer>
          <Link to={userEditPath}>
            <Button onClick={onClickEdit}>แก้ไขข้อมูล</Button>
          </Link>
        </AppModal.Footer>
      </AppModal>
    </>
  )
}

const LabelContainer = styled('div')`
  margin-bottom: 8px;
`

const ValueContainer = styled('div')`
  margin-bottom: 24px;
`

type RowItemType = {
  label: string
  value: string
}

export const RowItem = (props: RowItemType) => {
  return (
    <Col xs={24} md={12} style={{ paddingRight: '16px' }}>
      <LabelContainer>
        <Txt ag="body16">{props.label}</Txt>
      </LabelContainer>
      <ValueContainer>
        <Txt ag="header24">{props.value}</Txt>
      </ValueContainer>
    </Col>
  )
}
