import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { first, identity, pickBy, sortBy } from 'lodash'

import { GET_SERVICES } from 'services/enco-service/enco-service-service'
import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'

import { ResponseType } from '../response-type'

import { IMutationServiceContentsParams } from './service-content-service-param'
import {
  IMasterServiceContentFunc,
  IMutationServiceContentResponse,
  IServiceContentData,
} from './service-content-service-response'

export const SERVICE_CONTENT = '/ServiceContent'
export const SEARCH_SERVICE_CONTENT = '/SearchServiceContent'
const GET_MASTER_SERVICE_CONTENT_FUNC = '/GetMasterServiceContentFunc'

export enum EnumMasterServiceContentFunc {
  MEMBER = 'Member',
  BOOKING = 'Booking',
  SPONSOR = 'Sponsor',
}

export const useGetSearchServiceContentsQRY = (serviceId?: number, contentFunctionId?: number) => {
  return useQuery(
    [SEARCH_SERVICE_CONTENT, { serviceId, contentFunctionId }],
    async () => {
      const response = await encoClient.post<ResponseType<IServiceContentData[]>>(
        `${SEARCH_SERVICE_CONTENT}`,
        pickBy(
          {
            ServiceId: serviceId,
            Funct: contentFunctionId,
          },
          identity,
        ),
        {
          headers: {
            'Content-Type': ContentType.FORM_DATA,
          },
        },
      )
      return response.data.data
    },
    {
      enabled: !!serviceId,
    },
  )
}

export const useGetLastServiceContentQRY = (serviceId?: number, contentFunctionId?: number) => {
  return useQuery(
    [SEARCH_SERVICE_CONTENT, { serviceId, contentFunctionId }, 'last'],
    async () => {
      const response = await encoClient.post<ResponseType<IServiceContentData[]>>(
        `${SEARCH_SERVICE_CONTENT}`,
        pickBy(
          {
            ServiceId: serviceId,
            Funct: contentFunctionId,
          },
          identity,
        ),
        {
          headers: {
            'Content-Type': ContentType.FORM_DATA,
          },
        },
      )
      const dataSortByModify = sortBy(response.data.data, (d) => {
        return -dayjs(d.modifydate).unix()
      })
      return first(dataSortByModify)
    },
    {
      enabled: !!serviceId,
    },
  )
}

export const useGetMasterServiceContentFuncQRY = () => {
  return useQuery([GET_MASTER_SERVICE_CONTENT_FUNC], async () => {
    const response = await encoClient.get<ResponseType<IMasterServiceContentFunc[]>>(
      `${GET_MASTER_SERVICE_CONTENT_FUNC}`,
    )
    return response.data.data
  })
}

export const useGetServiceContentQRY = (serviceContentId?: number) => {
  return useQuery(
    [SERVICE_CONTENT, { serviceContentId }],
    async () => {
      const response = await encoClient.get<ResponseType<IServiceContentData>>(`${SERVICE_CONTENT}`, {
        params: {
          id: serviceContentId,
        },
      })
      return response.data.data
    },
    {
      enabled: !!serviceContentId,
    },
  )
}

export const useMutationServiceContentMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: IMutationServiceContentsParams) => {
      const response = await encoClient.post<ResponseType<IMutationServiceContentResponse>>(SERVICE_CONTENT, params, {
        headers: {
          'Content-Type': ContentType.JSON,
        },
      })

      return response.data.data
    },
    {
      onSuccess: (response, params) => {
        const { ServiceId: serviceId } = params
        queryClient.invalidateQueries([GET_SERVICES])
        queryClient.invalidateQueries([SEARCH_SERVICE_CONTENT, { serviceId: Number(serviceId) }])
        queryClient.invalidateQueries([SERVICE_CONTENT])
      },
    },
  )
}
