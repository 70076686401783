import { orderBy } from 'lodash'
import { useParams } from 'react-router-dom'

import {
  useGetServicePropQRY,
  useGetServicePropSlotQRY,
} from 'services/property-management/property-management-service'

import { ISlot, PropertySlotForm } from './PropertySlotForm'

export const PagePropertyEdit = () => {
  const query = useParams()
  const serviceId = Number(query.serviceId)
  const propertyId = Number(query.propertyId)

  const { data: getServicePropSlot, isFetching: isFetchingGetServicePropSlot } = useGetServicePropSlotQRY({
    serviceId: serviceId,
    servicePropId: propertyId,
    day: 'all',
  })

  const { data: getServiceProp, isFetching: isFetchingGetServiceProp } = useGetServicePropQRY({
    id: propertyId,
  })

  const mapKeyItem = orderBy(getServicePropSlot, 'mdSlotIdStart')

  const itemList = mapKeyItem.reduce((acc: Record<string, ISlot[]>, curr) => {
    if (!acc[curr.day]) {
      acc[curr.day] = []
    }
    acc[curr.day].push(curr)
    return acc
  }, {})
  const renameKeys = (keysMap: any, obj: any) =>
    Object.keys(obj).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [keysMap[key] || key]: obj[key] },
      }),
      {},
    )

  const renameKey = renameKeys(
    {
      sun: 'วันอาทิตย์',
      mon: 'วันจันทร์',
      tue: 'วันอังคาร',
      wed: 'วันพุธ',
      thu: 'วันพฤหัส',
      fri: 'วันศุกร์',
      sat: 'วันเสาร์',
    },
    itemList,
  )
  const initialValuesServiceProp = { active: getServiceProp?.active, name: getServiceProp?.name }
  const initialValuesSlot = {
    sun: getServiceProp?.sun ? true : false,
    mon: getServiceProp?.mon ? true : false,
    tue: getServiceProp?.tue ? true : false,
    wed: getServiceProp?.wed ? true : false,
    thu: getServiceProp?.thu ? true : false,
    fri: getServiceProp?.fri ? true : false,
    sat: getServiceProp?.sat ? true : false,
    ...renameKey,
  }

  return !isFetchingGetServiceProp && !isFetchingGetServicePropSlot ? (
    <PropertySlotForm
      isEdit
      initialValuesSlot={initialValuesSlot}
      initialValuesServiceProp={initialValuesServiceProp}
    />
  ) : (
    <></>
  )
}
