import { Card, Col, Form, notification, Radio, Row } from 'antd'
import { styled } from 'goober'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { UserTypeEnum } from '_backend/constants/user-management-constants'
import { backendPaths } from 'paths/backend-path-config'
import { IUserSaveParams } from 'services/user/user-param'
import { IGetUserResponse } from 'services/user/user-response'
import { useGetUserDetailQRY, useSaveUserDetailMTT } from 'services/user/user-service'

import { IUserDetailFormValue, UserDetailForm } from './UserDetailForm'

const ButtonGroupRow = styled(Row)`
  margin-bottom: 32px;
`

const convertToFormValues = (user: IGetUserResponse): IUserDetailFormValue => ({
  prefix: user.prefix,
  accountType: user.accountType,
  active: user.active === 1,
  firstName: user.firstname,
  lastName: user.lastname,
  email: user.email,
  logon: user.logon,
  profile: user.profile,
  tel: user.tel,
  userGroupId: user.userGrpId,
})

export const PageUserEdit = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const query = new URLSearchParams(search)

  const [form] = Form.useForm()
  const { data: UserDetail } = useGetUserDetailQRY(query.get('userId') || '')
  const { mutateAsync: saveUser } = useSaveUserDetailMTT()

  const [userType, setUserType] = useState<UserTypeEnum>(UserTypeEnum.enco)

  const onUserTypeChange = useCallback(
    (type: UserTypeEnum) => {
      setUserType(type)
    },
    [setUserType],
  )

  const handleSaveSuccess = useCallback(() => {
    navigate(backendPaths.userList())
    notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลผู้ใช้งานสำเร็จ', duration: 2 })
  }, [navigate])

  const handleSubmitForm = useCallback(
    async (values: IUserDetailFormValue) => {
      if (UserDetail) {
        const userForSave: IUserSaveParams = {
          Userid: UserDetail.userid,
          Prefix: values.prefix,
          Firstname: values.firstName,
          Lastname: values.lastName,
          Email: values.email,
          Empcode: UserDetail.empcode,
          Logon: values.logon,
          Tel: values.tel,
          UserTypeId: UserDetail.userTypeId,
          MdAccountTypeId: UserDetail.mdAccountTypeId,
          Profile: values.profile,
          UserGrpId: values.userGroupId,
          Active: values.active ? 1 : 0,
        }
        if (UserDetail.company) userForSave.Company = UserDetail.company
        await saveUser(userForSave, { onSuccess: handleSaveSuccess })
      }
    },
    [saveUser, UserDetail, handleSaveSuccess],
  )

  useEffect(() => {
    if (UserDetail?.userTypeId) setUserType(UserDetail?.userTypeId)
  }, [UserDetail?.userTypeId])

  return (
    <>
      <ContentHeader subTitle="แก้ไขผู้ใช้งาน" />
      <ContentBody max-width="686px">
        <Card>
          {UserDetail && (
            <Form
              layout="vertical"
              form={form}
              initialValues={convertToFormValues(UserDetail)}
              onFinish={handleSubmitForm}
            >
              <ButtonGroupRow>
                <Col sm={{ span: 12 }} span={24}>
                  <Radio.Group value={userType} buttonStyle="solid" onChange={(e) => onUserTypeChange(e.target.value)}>
                    {userType === UserTypeEnum.enco && (
                      <Radio.Button value={UserTypeEnum.enco}>พนักงาน EnCo</Radio.Button>
                    )}
                    {userType === UserTypeEnum.bsa && <Radio.Button value={UserTypeEnum.bsa}>พนักงาน BSA</Radio.Button>}
                  </Radio.Group>
                </Col>
              </ButtonGroupRow>

              <UserDetailForm userType={userType} />
            </Form>
          )}
        </Card>
      </ContentBody>
    </>
  )
}
