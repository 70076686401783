import generateCalendar, { CalendarProps } from 'antd/lib/calendar/generateCalendar'
import locale from 'antd/lib/date-picker/locale/th_TH'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'

import type { Dayjs } from 'dayjs'

const NewCalendar = generateCalendar<Dayjs>(dayjsGenerateConfig)

type NewCalendarProps = CalendarProps<Dayjs>

export const Calendar = (props: NewCalendarProps) => {
  const { ...restProps } = props
  return <NewCalendar locale={locale} {...restProps} />
}
