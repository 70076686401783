import { faGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, Menu, Tag } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { useCallback, useMemo } from 'react'

import { SortableTable } from '_backend/components/SortableTable'
import { GetServiceTypeEnum } from 'services/enco-service/enco-service-param'
import { IServiceData } from 'services/enco-service/enco-service-response'
import { useGetServicesQRY, useMutationSetServiceRecNoMTT } from 'services/enco-service/enco-service-service'
import theme from 'theme/goober'

export const GearIcon = styled(FontAwesomeIcon)`
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
`

export const GearIconDisabled = styled(FontAwesomeIcon)`
  color: ${theme.color.darkGray};
  cursor: not-allowed;
`

interface ServiceTablePropsType {
  txtSearch?: string
  serviceType: GetServiceTypeEnum
  renderMenuItems: (record: IServiceData) => ItemType[]
}
export const ServiceTable = (props: ServiceTablePropsType) => {
  const { txtSearch, serviceType, renderMenuItems } = props

  const { mutate: setServiceRecNo } = useMutationSetServiceRecNoMTT()
  const { data: dataSource, isLoading } = useGetServicesQRY({
    serviceType,
    txtSearch,
  })

  const columns = useMemo(
    (): ColumnType<IServiceData>[] => [
      {
        title: 'ลำดับ',
        dataIndex: 'recno',
        width: '50px',
        align: 'center',
      },
      {
        title: 'ชื่อบริการ',
        dataIndex: 'name',
      },
      {
        title: 'ประเภทบริการ',
        dataIndex: 'serviceType',
      },
      {
        title: 'สถานะ',
        dataIndex: 'active',
        render(value) {
          return <Tag color={value ? 'success' : 'default'}>{value ? 'Active' : 'Inactive'}</Tag>
        },
      },
      {
        title: 'Modify By',
        dataIndex: 'modifystfnm',
      },
      {
        title: 'Modify Date',
        dataIndex: 'modifydatenm',
      },
      {
        title: '',
        dataIndex: 'action',
        width: '16px',
        render: (value, record) => {
          return record.isModify === 1 ? (
            <Dropdown trigger={['click']} overlay={<Menu items={renderMenuItems(record)} />} placement="bottomRight">
              <div>
                <GearIcon icon={faGear} />
              </div>
            </Dropdown>
          ) : (
            <div>
              <GearIconDisabled icon={faGear} />
            </div>
          )
        },
      },
    ],
    [renderMenuItems],
  )

  const handleSortEnd = useCallback(
    (sortedSeq: number, sortedData: IServiceData) => {
      setServiceRecNo({
        newRow: sortedSeq,
        id: sortedData.id,
      })
    },
    [setServiceRecNo],
  )

  return (
    <SortableTable
      dataSource={dataSource}
      columns={columns}
      loading={isLoading}
      rowKey="id"
      onSortEnd={handleSortEnd}
    />
  )
}
