import { Button, Card, Col, Form, Radio, Row, Select, Space } from 'antd'
import { Dayjs } from 'dayjs'
import { styled } from 'goober'
import { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { UploadImageFormItem } from '_backend/components/upload/UploadImageFormItem'
import { NUMBER_MORE_THAN_ZERO, requiredCustomMessage } from '_backend/constants/form-rules'
import { SlotType } from '_backend/constants/item-management-constants'
import { ItemTypeIdEnum } from '_backend/constants/item-type'
import { Input } from 'components/Input'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import NumberInput from 'components/NumberInput'
import RangePicker from 'components/RangePicker'
import { Txt } from 'components/Txt'
import { backendPaths } from 'paths/backend-path-config'
import { IGetItemTypeResponse, IGetServiceItemResponse } from 'services/item-management/item-management-response'
import { useGetItemTypeListQRY, useGetServiceItemListQRY } from 'services/item-management/item-management-service'
import { IServicePropertyResponse } from 'services/service-property/service-property-response'
import { useGetServicePropListQRY } from 'services/service-property/service-property-service'

const SectionTxt = styled(Txt)`
  margin-bottom: 16px;
  margin-top: 16px;
`

const activeStatusOptions = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
]

const slotTypes = () => {
  return Object.values(SlotType).map((item) => ({
    label: item,
  }))
}

export interface IServiceItemFormValue {
  active: boolean
  serviceName: string
  itemTypeId: number
  servicePropId: number
  name: string
  unit: string
  slotType: SlotType | null
  encoPrice: number
  generalPrice: number
  memberPrice: number
  discount: number
  rageDate?: Dayjs[]
  picture?: File
}

interface IUserDetailFormProps {
  initialValue?: Partial<IServiceItemFormValue>
  serviceId: number
  onSubmitForm: (value: IServiceItemFormValue) => void
  isEdit?: boolean
  onDeleteItem?: () => void
  itemId?: number
}

const otherItemTypeExclude = 2
const ozoneItemType = [2, 3]

const handleCanSelSlotType = (items: IGetServiceItemResponse[], itemId: number, propertyId: number) => {
  return items.filter((item) => item.id !== itemId && item.tbServicePropId === propertyId).length < 1
}

export const ServiceItemForm = (props: IUserDetailFormProps) => {
  const modalConfirm = useModalConfirm()
  const { initialValue, onSubmitForm, serviceId, isEdit = false, onDeleteItem, itemId = 0 } = props
  const { data: itemTypeList } = useGetItemTypeListQRY()
  const { data: servicePropList } = useGetServicePropListQRY({ ServiceId: serviceId })
  const { data: normalItems } = useGetServiceItemListQRY({ ServiceId: serviceId, slotType: SlotType.NORMAL })
  const { data: peakItems } = useGetServiceItemListQRY({ ServiceId: serviceId, slotType: SlotType.PEAK })
  const { data: registerItems } = useGetServiceItemListQRY({
    ServiceId: serviceId,
    itemTypeId: ItemTypeIdEnum.register,
  })

  const [form] = Form.useForm()
  const [discount, setDiscount] = useState<number>(initialValue?.discount || 0)
  const [itemType, setItemType] = useState<number>(initialValue?.itemTypeId || -1)
  const [submitted, setSubmitted] = useState(false)
  const [selectedProperty, setSelectedProperty] = useState(initialValue?.servicePropId || 0)

  const handleChangeSelectProperty = useCallback(
    (value: number) => {
      form.setFields([{ name: 'slotType', value: undefined }])
      setSelectedProperty(value)
    },
    [form],
  )

  const canSelectRegister = useMemo(() => {
    if (registerItems) return registerItems?.filter((item) => item.id !== itemId).length < 1
    return true
  }, [registerItems, itemId])

  const canSelectSlotType = useCallback(
    (type: SlotType) => {
      const items = type === SlotType.NORMAL ? normalItems : peakItems
      if (items) return handleCanSelSlotType(items, itemId, selectedProperty)
      return true
    },
    [normalItems, peakItems, itemId, selectedProperty],
  )

  const itemTypeListFilterByServiceId = useMemo(() => {
    if (itemTypeList) {
      if (serviceId === 2) return itemTypeList.filter((item) => ozoneItemType.includes(item.id))
      return itemTypeList.filter((type) => type.id !== otherItemTypeExclude)
    }
    return []
  }, [itemTypeList, serviceId])

  const handleFinishForm = useCallback(
    (values: IServiceItemFormValue) => {
      modalConfirm.show({
        title: isEdit ? 'ยืนยันการแก้ไขไอเทม' : 'ยืนยันการสร้างไอเทม',
        content: isEdit
          ? `คุณยืนยันที่จะแก้ไขไอเทม ${values.name} นี้หรือไม่?`
          : `คุณยืนยันที่จะสร้างไอเทม ${values.name} นี้หรือไม่?`,
        type: 'default',
        onOk: () => onSubmitForm(values),
      })
    },
    [modalConfirm, isEdit, onSubmitForm],
  )

  const handleClickDeleteItem = useCallback(() => {
    onDeleteItem &&
      modalConfirm.show({
        title: 'ยืนยันการลบไอเทม',
        content: 'คุณยืนยันที่จะลบไอเทมนี้หรือไม่?',
        type: 'danger',
        onOk: () => onDeleteItem(),
      })
  }, [modalConfirm, onDeleteItem])

  const handleChangeDiscount = useCallback(
    (value: any) => {
      setDiscount(value)
      form.setFieldsValue({ rageDate: undefined })
    },
    [form],
  )

  const isRangeDateRequired = useCallback(() => {
    if (submitted) {
      form.validateFields()
    }
    return discount > 0
  }, [discount, form, submitted])

  const handleChangeItemType = useCallback(
    (value: any) => {
      form.setFieldsValue({ ...form.getFieldsValue(), servicePropId: undefined, slotType: undefined })
      setItemType(value)
    },
    [form],
  )

  const isPropertiesItemType = useCallback(() => {
    return itemType === ItemTypeIdEnum.properties
  }, [itemType])

  return (
    <>
      <Form layout="vertical" form={form} initialValues={initialValue} onFinish={handleFinishForm}>
        <Card>
          <Txt ag="header30">รายละเอียดไอเทม</Txt>

          <Row gutter={24}>
            <Col sm={{ span: 12 }} span={24}>
              <Form.Item
                name="active"
                label="สถานะไอเทม"
                required
                rules={requiredCustomMessage('กรุณาเลือกสถานะไอเทม')}
              >
                <Radio.Group options={activeStatusOptions} />
              </Form.Item>
            </Col>
            {isPropertiesItemType() && (
              <Col sm={{ span: 12 }} span={24}>
                <Form.Item
                  name="servicePropId"
                  label="Property"
                  required={isPropertiesItemType()}
                  rules={isPropertiesItemType() ? requiredCustomMessage('กรุณาเลือก Property') : undefined}
                >
                  <Select
                    placeholder="เลือก Property"
                    disabled={!isPropertiesItemType()}
                    onChange={handleChangeSelectProperty}
                  >
                    {servicePropList
                      ?.filter((prop) => Boolean(prop.active))
                      .map((option: IServicePropertyResponse) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={24}>
            <Col sm={{ span: 12 }} span={24}>
              <Form.Item name="serviceName" label="บริการ">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col sm={{ span: 12 }} span={24}>
              <Form.Item
                name="itemTypeId"
                label="ประเภทไอเทม"
                required
                rules={requiredCustomMessage('กรุณาเลือกประเภทไอเทม')}
              >
                <Select placeholder="เลือกประเภทไอเทม" onChange={handleChangeItemType}>
                  {itemTypeListFilterByServiceId?.map((type: IGetItemTypeResponse) => (
                    <Select.Option
                      key={type.id}
                      value={type.id}
                      disabled={type.id === ItemTypeIdEnum.register && !canSelectRegister}
                    >
                      {type.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={{ span: 24 }} span={24}>
              <Form.Item name="name" label="ชื่อไอเทม" required rules={requiredCustomMessage('กรุณากรอกชิ่อไอเทม')}>
                <Input placeholder="กรอกชื่อไอเทม" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={{ span: 12 }} span={24}>
              <Form.Item name="unit" label="ชื่อหน่วย" required rules={requiredCustomMessage('กรุณากรอกชิ่อหน่วย')}>
                <Input placeholder="กรอกชื่อหน่วย" />
              </Form.Item>
            </Col>

            {isPropertiesItemType() && (
              <Col sm={{ span: 12 }} span={24}>
                <Form.Item
                  name="slotType"
                  label="ประเภทช่วงเวลาจอง"
                  required={isPropertiesItemType()}
                  rules={isPropertiesItemType() ? requiredCustomMessage('กรุณาเลือกประเภทช่วงเวลาจอง') : undefined}
                >
                  <Select placeholder="เลือกช่วงเวลาจอง" disabled={!isPropertiesItemType()}>
                    {slotTypes().map((type) => (
                      <Select.Option
                        key={type.label}
                        value={type.label}
                        disabled={!canSelectSlotType(type.label as SlotType)}
                      >
                        {type.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={24}>
            <Col md={{ span: 12 }} span={24}>
              <UploadImageFormItem
                style={{ width: '100%' }}
                form={form}
                fieldName="picture"
                label={<Txt ag="header30">ภาพไอเทม</Txt>}
                required={true}
                title="ขนาดที่แนะนำ 300 x 300 หรืออัตราส่วน 1:1"
              />
            </Col>
          </Row>
          <SectionTxt ag="header30">ราคา</SectionTxt>
          <Row gutter={24}>
            <Col sm={{ span: 8 }} span={24}>
              <Form.Item
                name="encoPrice"
                label="ราคาสำหรับพนักงาน EnCo"
                required
                rules={[...requiredCustomMessage('กรุณากรอกราคา'), ...NUMBER_MORE_THAN_ZERO]}
              >
                <NumberInput placeholder="กรอกราคาสำหรับพนักงาน EnCo" />
              </Form.Item>
            </Col>
            <Col sm={{ span: 8 }} span={24}>
              <Form.Item
                name="generalPrice"
                label="สำหรับบุคคลทั่วไป"
                required
                rules={[...requiredCustomMessage('กรุณากรอกราคา'), ...NUMBER_MORE_THAN_ZERO]}
              >
                <NumberInput placeholder="กรอกราคาสำหรับบุคคลทั่วไป" />
              </Form.Item>
            </Col>
            <Col sm={{ span: 8 }} span={24}>
              <Form.Item
                name="memberPrice"
                label="สำหรับสมาชิก"
                required
                rules={[...requiredCustomMessage('กรุณากรอกราคา'), ...NUMBER_MORE_THAN_ZERO]}
              >
                <NumberInput placeholder="กรอกราคาสำหรับสมาชิก" />
              </Form.Item>
            </Col>
          </Row>
          <SectionTxt ag="header30">ส่วนลด</SectionTxt>
          <Row gutter={24}>
            <Col sm={{ span: 8 }} span={24}>
              <Form.Item name="discount" label="ส่วนลด (%)" rules={[...NUMBER_MORE_THAN_ZERO]}>
                <NumberInput onChange={handleChangeDiscount} placeholder="กรอกส่วนลด" />
              </Form.Item>
            </Col>
            <Col sm={{ span: 16 }} span={24}>
              <Form.Item
                name="rageDate"
                label="วันที่เริ่มต้น - สิ้นสุดการใช้งานส่วนลด"
                rules={[
                  { required: isRangeDateRequired(), message: 'กรุณาเลือกวันที่เริ่มต้น - สิ้นสุดการใช้งานส่วนลด' },
                ]}
              >
                <RangePicker disabled={!discount} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Row style={{ marginTop: 8 }}>
          <Col span={24}>
            <Card className="w-100">
              <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                <Link to={backendPaths.itemManagementList({ routeParam: { serviceId } })}>
                  <Button>กลับ</Button>
                </Link>
                <Space>
                  {isEdit && (
                    <Button onClick={handleClickDeleteItem} danger>
                      ลบไอเทม
                    </Button>
                  )}
                  <Button htmlType="submit" type="primary" onClick={() => setSubmitted(true)}>
                    บันทึก
                  </Button>
                </Space>
              </Space>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  )
}
