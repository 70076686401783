import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Row, Space, ModalProps } from 'antd'
import { styled } from 'goober'
import { CSSProperties, PropsWithChildren } from 'react'

import { Txt } from 'components/Txt'

export interface IAppModalProps
  extends Omit<
    ModalProps,
    | 'footer'
    | 'afterClose'
    | 'closeIcon'
    | 'onOk'
    | 'okText'
    | 'okType'
    | 'okButtonProps'
    | 'cancelButtonProps'
    | 'cancelText'
  > {
  className?: string
}
export interface IHeaderProps {
  onCloseClick: () => void
}
interface IBodyProps {
  className?: string
  style?: CSSProperties
}
interface IFooterProps {}

const CloseIconButton = styled(FontAwesomeIcon)`
  cursor: pointer;
`

const Header = (props: PropsWithChildren<IHeaderProps>) => {
  const { onCloseClick, children } = props
  return (
    <div className="custom-modal-header">
      <Txt ag="header30">{children}</Txt>
      <CloseIconButton className="close-icon" onClick={onCloseClick} icon={faCircleXmark} />
    </div>
  )
}

const Body = (props: PropsWithChildren<IBodyProps>) => {
  const { className, ...restProps } = props
  return <div className={['custom-modal-body', className].join(' ')} {...restProps} />
}

const Footer = (props: PropsWithChildren<IFooterProps>) => {
  const { children } = props
  return (
    <Row justify="end" className="custom-modal-footer">
      <Space size={12}>{children}</Space>
    </Row>
  )
}

export const AppModal = (props: PropsWithChildren<IAppModalProps>) => {
  const { children, className, ...restProps } = props
  return (
    <Modal footer={false} closable={false} className={['custom-modal', className].join(' ')} forceRender {...restProps}>
      {children}
    </Modal>
  )
}

AppModal.Header = Header
AppModal.Body = Body
AppModal.Footer = Footer
