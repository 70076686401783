import { Button, Col, Form, FormInstance, Row, Select } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import { Dayjs } from 'dayjs'
import { useEffect, useMemo, useCallback } from 'react'

import { AppModal, IAppModalProps, IHeaderProps } from 'components/AppModal'
import RangePicker from 'components/RangePicker'
import {
  useGetMasterOrderStatusQTY,
  useGetMasterOrderTypeQTY,
} from 'services/order-enco-service/order-enco-service-service'

export enum FilterModalFieldName {
  DATE = 'date',
  ORDER_TYPE_ID = 'mdOrderTypeId',
  ORDER_STATUS_ID = 'mdOrderStatusId',
}

export interface FormValue {
  [FilterModalFieldName.DATE]?: Dayjs[] | null
  [FilterModalFieldName.ORDER_TYPE_ID]?: number
  [FilterModalFieldName.ORDER_STATUS_ID]?: number
}

export interface IModalOrderListFilterProps extends IAppModalProps {
  onCloseClick: IHeaderProps['onCloseClick']
  onSubmit: (value: FormValue) => void
  form: FormInstance
  initialValues: FormValue
}

export const ModalOrderListFilter = (props: IModalOrderListFilterProps) => {
  const { onCloseClick, form, onSubmit, initialValues, ...restProps } = props
  const { data: orderTypeData } = useGetMasterOrderTypeQTY()
  const { data: orderStatusData } = useGetMasterOrderStatusQTY()

  const orderTypeOptions = useMemo(() => {
    return orderTypeData?.map((v) => ({
      label: v.orderType,
      value: v.id,
    }))
  }, [orderTypeData])

  const orderStatusOptions = useMemo(() => {
    return orderStatusData?.map((v) => ({
      label: v.status,
      value: v.id,
    }))
  }, [orderStatusData])

  const handleSubmit = useCallback(
    (value: FormValue) => {
      onSubmit(value)
      onCloseClick()
    },
    [onCloseClick, onSubmit],
  )

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <AppModal {...restProps}>
      {props.visible && (
        <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={initialValues}>
          <AppModal.Header onCloseClick={onCloseClick}>Filter</AppModal.Header>
          <AppModal.Body>
            <Row gutter={12}>
              <Col span={24}>
                <FormItem label="วันที่" name={FilterModalFieldName.DATE}>
                  <RangePicker allowClear />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24} sm={12}>
                <FormItem label="ประเภท Order" name={FilterModalFieldName.ORDER_TYPE_ID}>
                  <Select style={{ width: '100%' }} options={orderTypeOptions} placeholder="ประเภท Order" allowClear />
                </FormItem>
              </Col>
              <Col span={24} sm={12}>
                <FormItem label="สถานะการชำระเงิน" name={FilterModalFieldName.ORDER_STATUS_ID}>
                  <Select
                    style={{ width: '100%' }}
                    options={orderStatusOptions}
                    placeholder="สถานะการชำระเงิน"
                    allowClear
                  />
                </FormItem>
              </Col>
            </Row>
          </AppModal.Body>
          <AppModal.Footer>
            <Button onClick={onCloseClick}>ยกเลิก</Button>
            <Button type="primary" htmlType="submit">
              ค้นหา
            </Button>
          </AppModal.Footer>
        </Form>
      )}
    </AppModal>
  )
}
