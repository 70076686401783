import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, Row, Space, Table, Tag } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ColumnsType } from 'antd/lib/table'
import dayjs, { Dayjs } from 'dayjs'
import { styled } from 'goober'
import { useCallback, useMemo, useState } from 'react'
import { FaFilter } from 'react-icons/fa'
import { Link, useParams } from 'react-router-dom'

import { SearchInput } from '_backend/components/SearchInput'
import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { apiDateFormat } from '_backend/constants/date-format'
import { BackButton } from 'components/BackButton'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { useExportOrderSearch } from 'services/order-enco-service/order-enco-service-service'
import { ISearchOrderResponse } from 'services/order-response/order-response-service-response'
import { useSearchOrderResponseQRY } from 'services/order-response/order-response-service-service'
import theme from 'theme/goober'

import { FilterModalFieldName, FormValue, ModalOrderListFilter } from './ModalOrderListFilter'
import { OrderCalendar } from './OrderCalendar'
import { getOrderStatusTagColor } from './TextOrderStatus'

const EyeIcon = styled(FontAwesomeIcon)`
  color: ${theme.color.lightBluePrimary};
  font-size: 24px;
  cursor: pointer;
`

const ContentContainer = styled(ContentBody)``

const useModal = () => {
  const [visible, setVisible] = useState(false)
  const onOpen = useCallback(() => {
    setVisible(true)
  }, [])
  const onClose = useCallback(() => {
    setVisible(false)
  }, [])

  return {
    visible,
    onOpen,
    onClose,
  }
}

export const PageOrderList = () => {
  const { serviceId } = useParams()

  const [txtSearch, setTxtSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const filterModal = useModal()
  const [filterModalFormInstance] = useForm<FormValue>()
  const [filterParams, setFilterParams] = useState<any>({})

  const { data: orderList, isFetching } = useSearchOrderResponseQRY({
    ServiceId: Number(serviceId),
    txtSearch,
    ...filterParams,
    startDate: filterParams?.startDate?.format(apiDateFormat),
    endDate: filterParams?.endDate?.format(apiDateFormat),
    bookStartDate: filterParams?.bookStartDate?.format(apiDateFormat),
    bookEndDate: filterParams?.bookEndDate?.format(apiDateFormat),
  })
  const { data: service } = useGetServiceQRY(Number(serviceId))

  const handleSearch = useCallback((value: string) => {
    setTxtSearch(value)
  }, [])

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])

  const columns = useMemo(
    (): ColumnsType<ISearchOrderResponse> => [
      {
        title: 'ลำดับ',
        width: '50px',
        align: 'center',
        render: (_: any, record: ISearchOrderResponse, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      {
        title: 'Order Date',
        dataIndex: 'orderDateTimeNm',
        key: 'orderDateTimeNm',
      },
      {
        title: 'หมายเลขออเดอร์',
        dataIndex: 'orderNo',
        key: 'orderNo',
      },
      {
        title: 'ประเภทออเดอร์',
        dataIndex: 'orderTypeNm',
        key: 'orderTypeNm',
      },
      {
        title: 'สถานะ',
        render: (_: any, record: ISearchOrderResponse) => (
          <Tag color={getOrderStatusTagColor(record)}>{record.statusNm}</Tag>
        ),
      },
      {
        title: 'ประเภทใบเสร็จ',
        dataIndex: 'receiptTypeNm',
        key: 'receiptTypeNm',
      },
      {
        title: 'หมายเลขใบเสนอราคา',
        render: (_: any, record: ISearchOrderResponse) => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Txt ag="body20">{record.quotaNo || '-'}</Txt>
          </div>
        ),
      },
      {
        title: 'Modify Date',
        dataIndex: 'modifydatenm',
        key: 'modifydatenm',
      },
      {
        title: '',
        key: 'action',
        render: (_: any, record: ISearchOrderResponse) => (
          <Link
            to={backendPaths.serviceOrderDetail({ routeParam: { orderId: record.id, serviceId: Number(serviceId) } })}
          >
            <EyeIcon icon={faEye} />
          </Link>
        ),
      },
    ],
    [currentPage, serviceId, currentPageSize],
  )

  const handleFilterClearClick = useCallback(() => {
    filterModalFormInstance.resetFields()
    setFilterParams({
      mdOrderTypeId: undefined,
      mdOrderStId: undefined,
      startDate: undefined,
      endDate: undefined,
    })
    setCalendarValue(null)
    setCurrentPage(1)
  }, [filterModalFormInstance])

  const handleFilterSubmit = useCallback((value: FormValue) => {
    setFilterParams({
      mdOrderTypeId: value.mdOrderTypeId,
      mdOrderStId: value.mdOrderStatusId,
      startDate: value.date?.[0],
      endDate: value.date?.[1],
      bookStartDate: undefined,
      bookEndDate: undefined,
    })
    setCalendarValue(null)
    setCurrentPage(1)
  }, [])

  const [calendarValue, setCalendarValue] = useState<Dayjs | null>(null)
  const handleCalendarChange = useCallback((date: Dayjs | null) => {
    setCalendarValue(date)
    setFilterParams({
      bookStartDate: date,
      bookEndDate: date,
      startDate: undefined,
      endDate: undefined,
      [FilterModalFieldName.ORDER_STATUS_ID]: undefined,
      [FilterModalFieldName.ORDER_TYPE_ID]: undefined,
    })
    setCurrentPage(1)
  }, [])

  const { mutateAsync: exportData } = useExportOrderSearch()
  const handleExport = useCallback(() => {
    try {
      exportData({
        ServiceId: Number(serviceId),
        ...filterParams,
        startDate: filterParams?.startDate?.format(apiDateFormat),
        endDate: filterParams?.endDate?.format(apiDateFormat),
        bookStartDate: filterParams?.bookStartDate?.format(apiDateFormat),
        bookEndDate: filterParams?.bookEndDate?.format(apiDateFormat),
      })
    } catch {}
  }, [exportData, filterParams, serviceId])
  console.log('== orderList', orderList)

  return (
    <>
      <ContentHeader title={service?.name} subTitle="Order Management"></ContentHeader>

      <ContentBody>
        <ContentContainer>
          <Card>
            <OrderCalendar
              serviceId={Number(serviceId)}
              selectedValue={calendarValue}
              onSelected={handleCalendarChange}
            />
          </Card>
          <Card style={{ marginTop: 8 }}>
            <Space
              size={['large', 'small']}
              wrap
              style={{
                justifyContent: 'space-between',
                backgroundColor: theme.color.white,
                width: '100%',
                padding: '32px 12px',
              }}
            >
              <Txt ag="header30">Order List</Txt>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <FaFilter
                    onClick={filterModal.onOpen}
                    color={theme.color.lightBluePrimary}
                    style={{ cursor: 'pointer', marginRight: 8 }}
                  />
                </Col>
                <Col>
                  <Button onClick={handleFilterClearClick}>Clear Filter</Button>
                </Col>
                <Col>
                  <SearchInput placeholder="ค้นหาออเดอร์ของคุณ" onSearch={handleSearch} />
                </Col>
                <Col>
                  <Button danger onClick={handleExport}>
                    Export
                  </Button>
                </Col>

                <ModalOrderListFilter
                  onSubmit={handleFilterSubmit}
                  form={filterModalFormInstance}
                  visible={filterModal.visible}
                  onCloseClick={filterModal.onClose}
                  initialValues={{
                    ...filterParams,
                    [FilterModalFieldName.DATE]: filterParams.startDate
                      ? [dayjs(filterParams?.startDate), dayjs(filterParams?.endDate)]
                      : undefined,
                  }}
                />
              </Row>
            </Space>
            <Table
              loading={isFetching}
              scroll={{ x: '100%' }}
              rowKey="id"
              dataSource={orderList}
              columns={columns}
              pagination={{
                current: currentPage,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                onChange: handlePageChange,
              }}
            />
            <BackButton to={backendPaths.serviceList()} />
          </Card>
        </ContentContainer>
      </ContentBody>
    </>
  )
}
