import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import {
  IGetMasterSlotResponse,
  IGetSearchServicePropDayOffResponse,
  IGetSearchServicePropResponse,
  IGetServicePropSlotResponse,
} from './property-management-response'
import {
  IGetServicePropDayOffParam,
  ISearchServicePropDayOffParams,
  ISearchServicePropParams,
  IServicePropDayOffParams,
  IServicePropGetParams,
  IServicePropParams,
  IServicePropSlotGetParams,
  IServicePropSlotParams,
} from './property-management.param'

const GET_MASTER_SLOT = '/GetMasterSlot'
export const useGetMasterSlotQRY = () => {
  return useQuery([GET_MASTER_SLOT], async () => {
    const res = await encoClient.get<ResponseType<IGetMasterSlotResponse[]>>(GET_MASTER_SLOT)
    return res.data.data.filter((data) => data.active === 1)
  })
}

const GET_SERVICE_PROPS_DAY_OFF = '/ServicePropDayoff'
export const useGetServicePropDayOffQRY = (params: IGetServicePropDayOffParam) => {
  return useQuery(
    [GET_SERVICE_PROPS_DAY_OFF, params],
    async () => {
      const res = await encoClient.get<ResponseType<IGetSearchServicePropDayOffResponse>>(GET_SERVICE_PROPS_DAY_OFF, {
        params: params,
      })
      return res.data.data
    },
    {
      enabled: !!params.id,
    },
  )
}

const POST_SEARCH_SERVICE_PROP = '/SearchServiceProp'
export const useGetSearchServicePropQRY = (params: ISearchServicePropParams) => {
  return useQuery([POST_SEARCH_SERVICE_PROP, params], async () => {
    const res = await encoClient.post<ResponseType<IGetSearchServicePropResponse[]>>(POST_SEARCH_SERVICE_PROP, params, {
      headers: { 'Content-Type': ContentType.FORM_DATA },
    })
    return res.data.data
  })
}

const POST_SEARCH_SERVICE_PROP_DAY_OFF_PATH = '/SearchServicePropDayoff'
export const useGetSearchServicePropDayOffQRY = (
  params: ISearchServicePropDayOffParams,
  options?: {
    onSuccess?: (data: IGetSearchServicePropDayOffResponse[]) => void
    minimumAwaitTime?: number
    keepPreviousData?: boolean
  },
) => {
  const { onSuccess, minimumAwaitTime = 0, keepPreviousData } = options || {}
  return useQuery(
    [POST_SEARCH_SERVICE_PROP_DAY_OFF_PATH, params],
    async () => {
      const [res] = await Promise.all([
        encoClient.post<ResponseType<IGetSearchServicePropDayOffResponse[]>>(
          POST_SEARCH_SERVICE_PROP_DAY_OFF_PATH,
          params,
          {
            headers: { 'Content-Type': ContentType.FORM_DATA },
          },
        ),
        new Promise((resolve) => {
          setTimeout(() => {
            resolve(true)
          }, minimumAwaitTime)
        }),
      ])
      return res.data.data
    },
    {
      enabled: !!params.ServiceId || !!params.ServicePropId,
      onSuccess,
      keepPreviousData,
    },
  )
}
export const useGetSearchServicePropDayOffMTT = (options?: {
  onSuccess?: (data: IGetSearchServicePropDayOffResponse[]) => void
  minimumAwaitTime?: number
}) => {
  const { onSuccess, minimumAwaitTime = 0 } = options || {}
  const queryClient = useQueryClient()

  return useMutation(
    async (params: ISearchServicePropDayOffParams) => {
      const [res] = await Promise.all([
        encoClient.post<ResponseType<IGetSearchServicePropDayOffResponse[]>>(
          POST_SEARCH_SERVICE_PROP_DAY_OFF_PATH,
          params,
          {
            headers: { 'Content-Type': ContentType.FORM_DATA },
          },
        ),
        new Promise((resolve) => {
          setTimeout(() => {
            resolve(true)
          }, minimumAwaitTime)
        }),
      ])
      return res.data.data
    },
    {
      onSuccess: (response, params) => {
        queryClient.setQueryData([POST_SEARCH_SERVICE_PROP_DAY_OFF_PATH, params], response)
        onSuccess?.(response)
      },
    },
  )
}

const POST_SERVICE_PROP_DAY_OFF_PATH = '/ServicePropDayoff'
export const useSaveServicePropDayOffMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (param: IServicePropDayOffParams) => {
      const res = await encoClient.post<ResponseType<IGetSearchServicePropDayOffResponse>>(
        POST_SERVICE_PROP_DAY_OFF_PATH,
        param,
      )
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([POST_SEARCH_SERVICE_PROP_DAY_OFF_PATH])
      },
    },
  )
}

export const useSaveServicePropDayOffListMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: IServicePropDayOffParams[]) => {
      const response: IGetSearchServicePropDayOffResponse[] = []
      for (const param of params) {
        const res = await encoClient
          .post<ResponseType<IGetSearchServicePropDayOffResponse>>(POST_SERVICE_PROP_DAY_OFF_PATH, param)
          .catch((error) => {
            return Promise.reject(`${param.dayOff} ${param.slotIDStartLabel}-${param.slotIDEndLabel} ${error}`)
          })
        response.push(res.data.data)
      }
      return response
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([POST_SEARCH_SERVICE_PROP_DAY_OFF_PATH])
      },
    },
  )
}

export const useDeleteServicePropDayOff = () => {
  const queryClient = useQueryClient()
  return useMutation<unknown, unknown, { id: number }>(
    async (data) => {
      const res = await encoClient.delete<ResponseType<null>>(`${GET_SERVICE_PROPS_DAY_OFF}`, {
        headers: { 'Content-Type': ContentType.FORM_DATA },
        data,
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([POST_SEARCH_SERVICE_PROP_DAY_OFF_PATH])
      },
    },
  )
}

const POST_SERVICE_PROP = '/ServiceProp'
export const useSaveServicePropMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (param: IServicePropParams) => {
      const res = await encoClient.post<ResponseType<IGetSearchServicePropResponse>>(POST_SERVICE_PROP, param, {
        headers: { 'Content-Type': ContentType.FORM_DATA },
      })
      return res.data.data
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries([POST_SEARCH_SERVICE_PROP])
        queryClient.invalidateQueries([POST_SERVICE_PROP])
        queryClient.invalidateQueries([SERVICE_PROP_SLOT_PATH])
      },
    },
  )
}

const SERVICE_PROP_SLOT_PATH = '/ServicePropSlot'
export const useSaveServicePropSlotMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (param: IServicePropSlotParams) => {
      const res = await encoClient.post<ResponseType<IGetSearchServicePropResponse[]>>(SERVICE_PROP_SLOT_PATH, param, {
        headers: { 'Content-Type': ContentType.JSON },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([POST_SEARCH_SERVICE_PROP])
        queryClient.invalidateQueries([POST_SERVICE_PROP])
        queryClient.invalidateQueries([SERVICE_PROP_SLOT_PATH])
      },
    },
  )
}

export const useGetServicePropSlotQRY = (params: IServicePropSlotGetParams) => {
  return useQuery(
    [SERVICE_PROP_SLOT_PATH, params],
    async () => {
      const res = await encoClient.get<ResponseType<IGetServicePropSlotResponse[]>>(SERVICE_PROP_SLOT_PATH, {
        params: params,
      })
      return res.data.data
    },
    {
      enabled: !!params.serviceId && !!params.servicePropId,
    },
  )
}

export const useGetServicePropQRY = (params: IServicePropGetParams) => {
  return useQuery(
    [POST_SERVICE_PROP, params],
    async () => {
      const res = await encoClient.get<ResponseType<IGetSearchServicePropResponse>>(POST_SERVICE_PROP, {
        params: params,
      })
      return res.data.data
    },
    {
      enabled: !!params.id,
    },
  )
}
