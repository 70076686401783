import { Form, Row, Col, Select, Button } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Dayjs } from 'dayjs'
import { useCallback, useMemo } from 'react'

import { apiDateFormat } from '_backend/constants/date-format'
import { AppModal, IHeaderProps } from 'components/AppModal'
import RangePicker from 'components/RangePicker'
import { useGetMasterServiceTypeQRY } from 'services/enco-service/enco-service-service'

enum FilterFormFieldName {
  Rating = 'Rating',
  ServiceId = 'ServiceId',
  SaveDate = 'SaveDate',
}

interface FilterModalData {
  [FilterFormFieldName.Rating]: number
  [FilterFormFieldName.ServiceId]: number
  [FilterFormFieldName.SaveDate]: Dayjs[]
}

export interface SubmitData {
  [FilterFormFieldName.Rating]?: number
  [FilterFormFieldName.ServiceId]?: number
  StartDate?: string
  EndDate?: string
}

interface FilterModalProps {
  visible: boolean
  onCloseClick: IHeaderProps['onCloseClick']
  onSubmit: (value: SubmitData) => void
}

const ratingOptions = Array.from({ length: 5 }).map((_, index) => ({
  label: index + 1,
  value: index + 1,
}))

export const ModalFilter = (props: FilterModalProps) => {
  const { visible, onCloseClick, onSubmit } = props
  const [form] = useForm()

  const { data: masterServiceTypeData } = useGetMasterServiceTypeQRY()
  const serviceTypeOptions = useMemo(() => {
    return masterServiceTypeData?.map((v) => ({
      label: v.serviceType,
      value: v.id,
    }))
  }, [masterServiceTypeData])

  const handleSubmit = useCallback(
    (value: FilterModalData) => {
      onSubmit({
        [FilterFormFieldName.Rating]: value.Rating,
        [FilterFormFieldName.ServiceId]: value.ServiceId,
        StartDate: value.SaveDate?.[0].format(apiDateFormat),
        EndDate: value.SaveDate?.[1].format(apiDateFormat),
      })
      onCloseClick()
    },
    [onCloseClick, onSubmit],
  )

  return (
    <AppModal visible={visible}>
      <Form onFinish={handleSubmit} form={form}>
        <AppModal.Header onCloseClick={onCloseClick}>Filter</AppModal.Header>
        <AppModal.Body>
          <Row gutter={24}>
            <Col span={24} md={8}>
              <Form.Item name={FilterFormFieldName.Rating} label="คะแนนความพอใจ" labelCol={{ span: 24 }}>
                <Select options={ratingOptions} allowClear />
              </Form.Item>
            </Col>
            <Col span={24} md={16}>
              <Form.Item name={FilterFormFieldName.ServiceId} label="ประเภทบริการ" labelCol={{ span: 24 }}>
                <Select options={serviceTypeOptions} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name={FilterFormFieldName.SaveDate} label="ช่วงวันที่บันทึก" labelCol={{ span: 24 }}>
                <RangePicker allowClear />
              </Form.Item>
            </Col>
          </Row>
        </AppModal.Body>
        <AppModal.Footer>
          <Button type="primary" htmlType="submit">
            กรองข้อมูล
          </Button>
        </AppModal.Footer>
      </Form>
    </AppModal>
  )
}
