import { notification } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { apiDateFormat } from '_backend/constants/date-format'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { IServiceItemSaveParams } from 'services/item-management/item-management-param'
import { IGetServiceItemResponse } from 'services/item-management/item-management-response'
import {
  useDeleteServiceItemMTT,
  useGetServiceItemByIdQRY,
  useSaveServiceItemMTT,
} from 'services/item-management/item-management-service'

import { IServiceItemFormValue, ServiceItemForm } from './ServiceItemForm'

const convertToFormValues = (
  item?: IGetServiceItemResponse,
  serviceName?: string,
): IServiceItemFormValue | undefined => {
  if (!(item && serviceName)) return undefined
  const {
    itemTypeId,
    name,
    slotType,
    unit,
    discount,
    encoPrice,
    generalPrice,
    memberPrice,
    startdate,
    enddate,
    active,
    tbServicePropId,
    picture,
  } = item
  return {
    active: active === 1 ? true : false,
    itemTypeId,
    name,
    serviceName,
    slotType,
    unit,
    discount,
    encoPrice,
    generalPrice,
    memberPrice,
    rageDate: startdate && enddate ? [dayjs(startdate), dayjs(enddate)] : undefined,
    servicePropId: tbServicePropId,
    picture,
  }
}

export const PageServiceItemEdit = () => {
  const navigate = useNavigate()
  const { serviceId: ServiceIdParams, itemId } = useParams()
  const serviceId = Number(ServiceIdParams)

  const { data: service } = useGetServiceQRY(serviceId)
  const { data: currentItem, refetch } = useGetServiceItemByIdQRY(Number(itemId))
  const { mutateAsync: saveItem } = useSaveServiceItemMTT()
  const { mutateAsync: deleteItem } = useDeleteServiceItemMTT()

  const initialValue = useMemo((): IServiceItemFormValue | undefined => {
    return convertToFormValues(currentItem, service?.name)
  }, [currentItem, service])

  const handleDeleteItem = useCallback(async () => {
    if (currentItem) {
      await deleteItem({ id: currentItem.id })
      navigate(backendPaths.itemManagementList({ routeParam: { serviceId } }))
      notification.success({ message: 'สำเร็จ', description: 'ลบไอเทมสำเร็จ', duration: 2 })
    }
  }, [currentItem, navigate, serviceId, deleteItem])

  const handleSubmitForm = useCallback(
    async (value: IServiceItemFormValue) => {
      const saveParams: IServiceItemSaveParams = {
        Id: currentItem?.id || -1,
        ServiceId: serviceId,
        ItemTypeId: value.itemTypeId,
        Name: value.name,
        Unit: value.unit,
        SlotType: value.slotType,
        EncoPrice: value.encoPrice,
        GeneralPrice: value.generalPrice,
        MemberPrice: value.memberPrice,
        Picture: value.picture,
        Active: value.active ? 1 : 0,
      }
      if (value.discount > 0) saveParams.Discount = value.discount
      if (value.rageDate) {
        saveParams.StartDate = dayjs(value.rageDate[0]).format(apiDateFormat)
        saveParams.EndDate = dayjs(value.rageDate[1]).format(apiDateFormat)
      }
      if (value.servicePropId) saveParams.ServicePropId = value.servicePropId
      await saveItem(saveParams)
      navigate(backendPaths.itemManagementList({ routeParam: { serviceId } }))
      notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลไอเทมสำเร็จ', duration: 2 })
      refetch()
    },
    [serviceId, saveItem, currentItem, navigate, refetch],
  )
  if (!service) return null
  if (!currentItem) return null
  return (
    <>
      <ContentHeader title={service.name} subTitle="Edit Item" />
      <ContentBody>
        <ServiceItemForm
          itemId={currentItem.id}
          serviceId={serviceId}
          initialValue={initialValue}
          onSubmitForm={handleSubmitForm}
          onDeleteItem={handleDeleteItem}
          isEdit={true}
        />
      </ContentBody>
    </>
  )
}
