import { Badge } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { styled } from 'goober'
import { isEmpty } from 'lodash'
import { useCallback, useState, useRef } from 'react'

import { componentDisplayDateFormat } from '_backend/constants/date-format'
import { Calendar } from 'components/Calendar'
import { randomId } from 'helpers/string-helper'
import { useGetOrderCalendarQRY } from 'services/order-response/order-response-service-service'
import theme from 'theme/goober'

import type { BadgeProps } from 'antd'

const timeFormat = (time: string) => {
  const times = time.split(':')
  return `${times[0]} : ${times[1]}`
}

const mapBadgeDotColor = (status: BadgeProps['status']) =>
  status === 'success' ? theme.color.lightGreenPrimary : theme.color.softGray
const mapBadgeTextColor = (status: BadgeProps['status']) =>
  status === 'success' ? theme.color.lightGreenBackground : theme.color.grayCard

const Container = styled.div`
  background-color: ${theme.color.white};
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date {
    background: unset;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value {
    color: #181818;
  }
`

const ListBadge = styled<BadgeProps>(Badge)`
  .ant-badge-status-dot {
    margin-right: -4px;
    background-color: ${(props) => mapBadgeDotColor(props.status)};
  }
  .ant-badge-status-text {
    font-size: 14px;
    overflow-wrap: unset;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: ${(props) => mapBadgeTextColor(props.status)};
  }
`

interface IOrderCalendarProps {
  serviceId: number
  selectedValue: Dayjs | null
  onSelected: (date: Dayjs | null) => void
}

export const OrderCalendar = (props: IOrderCalendarProps) => {
  const [filter, setFilter] = useState<Dayjs>(dayjs())

  const { data: orders } = useGetOrderCalendarQRY({
    month: (filter?.month() || 0) + 1,
    year: filter?.year(),
    serviceId: props.serviceId,
  })

  const groupOrder = useCallback(() => {
    let OrderItemGrouped: {
      [key: string]: { key: string; time: string; name: string; badge: BadgeProps['status']; mdSlotId: number }[]
    } = {}
    orders?.forEach((order) => {
      const badge: BadgeProps['status'] = isEmpty(order.canceldate) ? 'success' : 'default'
      order.orderItem
        .filter((item) => item.mdSlotId !== null)
        .forEach((item) => {
          const bookingTime = dayjs(item.bookingDate).format(componentDisplayDateFormat)
          const displayItem = {
            key: item.itemnm + item.mdSlotId,
            time: `${timeFormat(item.slotStart)} - ${timeFormat(item.slotEnd)} น.`,
            name: item.itemnm,
            badge,
            mdSlotId: item.mdSlotId,
          }
          if (OrderItemGrouped[bookingTime]) {
            const existed = OrderItemGrouped[bookingTime].find((grouped) => grouped.key === displayItem.key)
            if (!existed) {
              OrderItemGrouped = { ...OrderItemGrouped, [bookingTime]: [...OrderItemGrouped[bookingTime], displayItem] }
            }
          } else {
            OrderItemGrouped = { ...OrderItemGrouped, [bookingTime]: [displayItem] }
          }
        })
    })
    return OrderItemGrouped
  }, [orders])

  const isDateSelectedRef = useRef(false)

  const handlePanelChange = useCallback(
    (date: Dayjs, mode: string) => {
      setFilter(date)

      if (!props.selectedValue) {
        setTimeout(() => {
          props.onSelected(null)
          isDateSelectedRef.current = false
        }, 0)
      }
    },
    [props],
  )

  const handleSelect = useCallback(
    (date: Dayjs) => {
      isDateSelectedRef.current = true
      props.onSelected(date)
    },
    [props],
  )

  const dateCellRender = (value: Dayjs) => {
    const listData = groupOrder()[value.format(componentDisplayDateFormat)]
    return (
      <>
        <div
          style={{
            position: 'absolute',
            inset: 0,
            background: value.isSame(props.selectedValue) ? '#00ccff1c' : 'unset',
            pointerEvents: 'none',
          }}
        />
        {listData &&
          listData
            .sort((a, b) => a.mdSlotId - b.mdSlotId)
            .map((item) => <ListBadge key={randomId()} status={item.badge} text={`${item.time} ${item.name}`} />)}
      </>
    )
  }

  return (
    <Container>
      <Calendar
        value={filter}
        dateCellRender={dateCellRender}
        onSelect={handleSelect}
        onPanelChange={handlePanelChange}
      />
    </Container>
  )
}
