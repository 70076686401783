/* eslint-disable no-template-curly-in-string */
import { Button, Card, Col, Form, notification, Row, Space } from 'antd'
import { useCallback, useMemo, useRef } from 'react'

import { TextEditor } from '_backend/components/TextEditor'
import { Input } from 'components/Input'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { Txt } from 'components/Txt'
import { notifyFormValidateFailed } from 'helpers/form-helper'
import { ISubmitServiceEmergencyContact } from 'services/emergency-contact/emergency-contact-service-param'
import { IServiceContentData } from 'services/service-content/service-content-service-response'
import { EnumMasterServiceContentFunc } from 'services/service-content/service-content-service-service'

import { TableEmergencyContacts, ITableEmergencyContactsContext } from './TableEmergencyContacts'

export interface EncoServiceContentFormValues {
  id: number
  serviceName: string

  emergencyContacts: ISubmitServiceEmergencyContact[]

  serviceContents: IServiceContentData[]
}

interface EncoServiceContentFormProps {
  initialValues?: Partial<EncoServiceContentFormValues>
  isOzone: boolean
  onSubmit: (values: EncoServiceContentFormValues) => Promise<void>
  onCancel: () => void
}
export const EncoServiceContentForm = (props: EncoServiceContentFormProps) => {
  const { onSubmit, onCancel, initialValues, isOzone } = props
  const modalConfirm = useModalConfirm()

  const [form] = Form.useForm<EncoServiceContentFormValues>()

  const emergencyContactContextRef = useRef<ITableEmergencyContactsContext>(null)

  const onConfirmSaveClick = useCallback(async () => {
    const values = form.getFieldsValue()
    await onSubmit(values)
    notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลสำเร็จ', duration: 2 })
  }, [form, onSubmit])

  const handleSubmit = useCallback(
    (values: EncoServiceContentFormValues) => {
      modalConfirm.show({
        type: 'default',
        title: `ยืนยันการแก้ไขข้อมูล EnCo Service`,
        content: `คุณยืนยันที่จะแก้ไขข้อมูล EnCo Service ${initialValues?.serviceName} นี้หรือไม่?`,
        onOk: onConfirmSaveClick,
      })
    },
    [initialValues?.serviceName, modalConfirm, onConfirmSaveClick],
  )

  const onAddEmergencyContactClick = useCallback(() => {
    emergencyContactContextRef.current?.openAddEmergencyContact?.()
  }, [])

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      onFinishFailed={notifyFormValidateFailed}
      scrollToFirstError
    >
      <Row gutter={[32, 32]}>
        {/* service detail */}
        <Col span={24}>
          <Card>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row align="middle" gutter={[24, 16]}>
                  <Col span={24}>
                    <Txt ag="header30">รายละเอียดบริการ</Txt>
                  </Col>
                  <Col sm={12} xs={24}>
                    <Form.Item label="ชื่อบริการ (Service Name)" name="serviceName">
                      <Input placeholder="กรอกข้อมูล" maxLength={255} disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {/* emergency contact table */}
              <Col span={24}>
                <Card className="w-100">
                  <Row justify="space-between">
                    <Txt ag="header30">ผู้ดูแล (Emergency Contacts)</Txt>
                    <Button onClick={onAddEmergencyContactClick}>เพิ่มผู้ดูแล</Button>
                  </Row>
                  <Form.Item name="emergencyContacts" shouldUpdate>
                    <TableEmergencyContacts ref={emergencyContactContextRef} />
                  </Form.Item>
                </Card>
              </Col>
              <Form.List name="serviceContents">
                {(fields) =>
                  fields.map((field) => {
                    return (
                      <Col key={field.key} span={24}>
                        <Form.Item name={[field.name, 'topic']} hidden>
                          <Input disabled />
                        </Form.Item>
                        <Form.Item name={[field.name, 'functnm']}>
                          <DynamicContentTitle serviceName={initialValues?.serviceName} isOzone={isOzone} />
                        </Form.Item>
                        <Form.Item name={[field.name, 'contenttx']}>
                          <TextEditor moduleName="enco-service" />
                        </Form.Item>
                      </Col>
                    )
                  })
                }
              </Form.List>
            </Row>
          </Card>
        </Col>
      </Row>
      <Card style={{ marginTop: 8 }}>
        <Row>
          <Col span={12} className="text-left">
            <Button onClick={onCancel} htmlType="reset">
              กลับ
            </Button>
          </Col>
          <Col span={12} className="text-right">
            <Space>
              <Button type="primary" htmlType="submit">
                บันทึก
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
    </Form>
  )
}

interface IDynamicContentTitleByFuncNameProps {
  value?: string // content functnm
  onChange?: () => void
  serviceName?: string
  isOzone: boolean
}
const DynamicContentTitle = (props: IDynamicContentTitleByFuncNameProps) => {
  const { value, isOzone, serviceName } = props

  const ozoneTitle = useMemo(() => {
    switch (value) {
      case EnumMasterServiceContentFunc.BOOKING:
        return 'เนื้อหาการจองอบโอโซนรถยนต์'
      case EnumMasterServiceContentFunc.SPONSOR:
        return 'เนื้อหาการอบโอโซนพิ้นที่'
      case EnumMasterServiceContentFunc.MEMBER:
      default:
        return value
    }
  }, [value])

  const stadiumTitle = useMemo(() => {
    switch (value) {
      case EnumMasterServiceContentFunc.MEMBER:
        return `เนื้อหาการสมัครสมาชิก${serviceName}`
      case EnumMasterServiceContentFunc.BOOKING:
        return `เนื้อหาการจอง${serviceName}`
      case EnumMasterServiceContentFunc.SPONSOR:
        return 'เนื้อหา Sponsor Package'
      default:
        return value
    }
  }, [serviceName, value])

  const contentTitle = useMemo(() => {
    if (isOzone) {
      return ozoneTitle
    } else {
      return stadiumTitle
    }
  }, [isOzone, ozoneTitle, stadiumTitle])

  return <Txt ag="header30">{contentTitle}</Txt>
}
