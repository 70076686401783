import { Button, Col, Divider, Form, Radio, Row, Select, Space } from 'antd'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { UserTypeEnum } from '_backend/constants/user-management-constants'
import { Input } from 'components/Input'
import { backendPaths } from 'paths/backend-path-config'
import { IGetUserGroupResponse } from 'services/user/user-response'
import { useGetUserGroupListQRY } from 'services/user/user-service'

const activeStatusOptions = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
]

export interface IUserDetailFormValue {
  prefix: string
  firstName: string
  lastName: string
  email: string
  tel: string
  userGroupId?: number
  profile?: string
  accountType?: string
  logon: string
  active: boolean
}

interface IUserDetailFormProps {
  userType?: UserTypeEnum
}

export const UserDetailForm = (props: IUserDetailFormProps) => {
  const { userType } = props
  const { data: UserGroupList = [] } = useGetUserGroupListQRY()

  const isEnco = useMemo(() => userType === UserTypeEnum.enco, [userType])
  const isBSA = useMemo(() => userType === UserTypeEnum.bsa, [userType])
  const isOther = useMemo(() => userType === UserTypeEnum.other, [userType])
  const isEnCoOrOther = useMemo(() => isEnco || isOther, [isEnco, isOther])

  return (
    <>
      <Row gutter={24}>
        <Col sm={{ span: 4 }} span={24}>
          <Form.Item name="prefix" label="คำนำหน้าชื่อ" rules={isBSA ? REQUIRED_RULES : []}>
            <Input disabled={isEnCoOrOther} placeholder="กรอกคำนำหน้า" />
          </Form.Item>
        </Col>
        <Col sm={{ span: 10 }} span={24}>
          <Form.Item name="firstName" label="ชื่อ" rules={isBSA ? REQUIRED_RULES : []}>
            <Input disabled={isEnCoOrOther} placeholder="กรอกชื่อ" />
          </Form.Item>
        </Col>
        <Col sm={{ span: 10 }} span={24}>
          <Form.Item name="lastName" label="นามสกุล" rules={isBSA ? REQUIRED_RULES : []}>
            <Input disabled={isEnCoOrOther} placeholder="กรอกนามสกุล" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col sm={{ span: 12 }} span={24}>
          <Form.Item name="email" label="อีเมล" rules={isBSA ? REQUIRED_RULES : []}>
            <Input disabled={isEnCoOrOther} placeholder="กรอกอีเมล" />
          </Form.Item>
        </Col>
        <Col sm={{ span: 12 }} span={24}>
          <Form.Item name="tel" label="เบอร์โทรศัพท์" rules={isBSA ? REQUIRED_RULES : []}>
            <Input disabled={isEnCoOrOther} placeholder="กรอกเบอร์โทรศัพท์" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col sm={{ span: 12 }} span={24}>
          <Form.Item name="userGroupId" label="กลุ่มผู้ใช้งาน" rules={REQUIRED_RULES}>
            <Select placeholder="เลือกกลุ่มผู้ใช้งาน" disabled={isOther}>
              {UserGroupList &&
                UserGroupList.filter((group) => Boolean(group.active)).map((group: IGetUserGroupResponse) => (
                  <Select.Option key={group.id} value={group.id}>
                    {group.userGrp}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col sm={{ span: 12 }} span={24}>
          <Form.Item name="profile" label="ชื่อโปรไฟล์" rules={isBSA ? REQUIRED_RULES : []}>
            <Input disabled={isEnCoOrOther} placeholder="กรอกชื่อโปรไฟล์" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col sm={{ span: 12 }} span={24}>
          <Form.Item name="accountType" label="Account Type">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col sm={{ span: 12 }} span={24}>
          <Form.Item name="logon" label="Logon" rules={isBSA ? REQUIRED_RULES : []}>
            <Input disabled={isEnCoOrOther} placeholder="กรอก Logon" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col sm={{ span: 12 }} span={24}>
          <Form.Item name="active" label="สถานะผู้ใช้งาน (Status)">
            <Radio.Group options={activeStatusOptions} />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Col>
          <Space>
            <Link to={backendPaths.userList()}>
              <Button>กลับ</Button>
            </Link>
            <Button htmlType="submit" type="primary">
              บันทึก
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  )
}
